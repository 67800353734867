import './style.scss'
import { Container, Row } from 'react-bootstrap'
import AboutUs from './templates/about-us'
import OurServices from './templates/our-services'
import ContactForm from '../contact-us'



const Pages = ({ title, template }) => {

  return (
    <Container className={'page'}>
      <Row>
        <div className={'page-title'}>
          <h1>{title}</h1>
        </div>
        {template === 'about-us' ? <AboutUs/> : ''}
        {template === 'our-service' ? <OurServices/> : ''}
        {template === 'contact-us' ? <ContactForm/> : ''}
      </Row>
    </Container>
  )
}

export default Pages
