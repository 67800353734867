import { Col, Row } from 'react-bootstrap'

import './block.scss'
import Services from './index'

const Block = ({ heading, description, list, subHeading }) => {

  let serviceList = Object.entries(list).map(([key, value]) => {
    return <Col md={4} key={key}><Services position={value.position}
                                           title={value.title}
                                           imagePath={value.imagePath}
                                           content={value.content}/></Col>
  })

  return (
    <Row className={'our-services-content-block'}>
      {'' !== heading ? (<Row className={'our-services-content-block-header'}>
        <Col md={12}>
          <h2
            className={'our-services-content-block-header-title'}>{heading}</h2>
        </Col>
        <Col md={12}
             className={'our-services-content-block-header-description'}>
          {description}
        </Col>
      </Row>) : ''}
      {'' !== subHeading ? <Row
        className={'our-services-content-block-subheading'}>
        <h3>{subHeading}</h3></Row> : ''}
      <Row className={'our-services-content-block-list'}>
        {Object.keys(list).length > 0 ? serviceList : ''}
      </Row>
    </Row>
  )
}

export default Block
