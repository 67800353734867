import './style.scss'
import { Card, Col, Figure, Row } from 'react-bootstrap'

import { useState } from 'react'

const Services = ({ position, imagePath, title, content }) => {

  const [ width, setWidth ] = useState(71 );
  const [ height, setHeight ] = useState(81 );

  const onImgLoad = ( {target:img} ) =>  {
    setWidth( img.offsetWidth )
    setHeight( img.offsetHeight )
  }

  const ServiceCard = () => {
    return <Card className={'services'} border="secondary">
      <Card.Body>
        <Figure>
          <Row className={'services-overlay'}>
            <Col sm={12} md={4}>
              <div className={'services-overlay-image'}>
                <Figure.Image
                  alt="171x180"
                  src={imagePath}
                  onLoad={onImgLoad}
                  width={ width }
                  height={ height }
                />
              </div>
            </Col>
            <Col sm={12} md={8}> { position === 'top'?  <Card.Title>{title}</Card.Title> : '' } </Col>
          </Row>
        </Figure>
        { position === 'bottom'?  <Card.Title>{title}</Card.Title> : '' }
        <Card.Text>{ content }</Card.Text>
      </Card.Body>
    </Card>
  }

  return <ServiceCard />
}

export default Services
