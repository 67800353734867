import './style.scss'
import {
  Container
} from 'react-bootstrap'

import NeedHelp from '../need-help'
import HealthType from '../health-type'
import HomeBanner from './HomeBanner'
import liverImage from '../../img/liver.png'
import heartImage from '../../img/heart.png'
import kidneyImage from '../../img/kidney.png'
import thyroidImage from '../../img/thyroid.png'
import sugarImage from '../../img/sugar.png'
import briefcaseImage from '../../img/briefcase.png'
import gearImage from '../../img/gear.png'
import listImage from '../../img/list.png'
import homeImage from '../../img/home.png'
import timerImage from '../../img/timer.png'
import resultImage from '../../img/result.png'
import RelatedTests from '../related-tests'
import healpingHeart from '../../img/helping-heart.png'
import FrequentlyBooked from '../frequently-booked'
import Rtest from '../../img/rtest.png';
import Testimonial from '../testimonial'
import feMaleImage from '../../img/female.png'
import maleImage from '../../img/male.png'
import pinkBg from '../../img/pink-bg.png'
import violetBg from '../../img/violet-bg.png'
import yellowBg from '../../img/yellow-bg.png'
import heartColorImage from '../../img/heart-color.png'
import thyroidColorImage from '../../img/thyroid-color.png'
import doctorColorImage from '../../img/doctor-color.png'
import Stats from '../stats'



const Home = () => {

  let typesList = {
    liver: {
      title : 'Liver',
      content: '',
      customClass: 'health-type-content-image health-type-content-image-liver',
      imagePath: liverImage
    },
    heart: {
      title : 'Cardiac Profile',
      content: '',
      customClass: 'health-type-content-image health-type-content-image-heart',
      imagePath: heartImage
    },
    kidney: {
      title : 'Kidney Function Test',
      content: '',
      customClass: 'health-type-content-image health-type-content-image-kidney',
      imagePath: kidneyImage
    },
    thyroid: {
      title : 'Thyroid profile',
      content: '',
      customClass: 'health-type-content-image health-type-content-image-thyroid',
      imagePath: thyroidImage
    },
    diabetes: {
      title : 'Diabetes',
      content: '',
      customClass: 'health-type-content-image health-type-content-image-diabetes',
      imagePath: sugarImage
    }
  };

  let howWeWork = {
    request: {
      title : '1. Request your test kit',
      content: 'The Test kit materials will be delivered right to your doorstep and includes prepaid expedited shipping to send your sample to lab.',
      customClass: 'health-type-content-image health-type-content-image-4',
      imagePath: timerImage
    },
    collect: {
      title : '2. Collect your samples',
      content: 'Once you register your test, a board certified physician reviews and approves your test order. Collect your sample and drop it in the mail.',
      customClass: 'health-type-content-image health-type-content-image-5',
      imagePath: homeImage
    },
    receive: {
      title : '3. Receive results in hours',
      content: 'Digital results and insights will be available on our secure plaform within hours. Easily share your results with doctor or family members.',
      customClass: 'health-type-content-image health-type-content-image-6',
      imagePath: resultImage
    }
  };

  let whyBookUs = {
    sampleCollection: {
      title: 'Home sample collect for free',
      content: 'A certified professional will collect your sample from preferred location',
      customClass: 'health-type-content-image health-type-content-image-1',
      imagePath: briefcaseImage,
    },
    digitalReport: {
      title: 'Get digital reports within day',
      content: 'Our Labs ensure turn- around-time of 24 hours from specimen pick',
      customClass: 'health-type-content-image health-type-content-image-2',
      imagePath: listImage,
    },
    offers: {
      title: 'Offers and affordable prices',
      content: 'Get great discounts and offers on tests and health packages.',
      customClass: 'health-type-content-image health-type-content-image-3',
      imagePath: gearImage,
    },
  }

  let relatedPackages = {
    cbc: {
      title: 'DIABETIC PACKAGE (EXECUTIVE)',
      description: 'Ideal for people who lead a stressful life,who suffer from diabetes.',
      imagePath: healpingHeart,
      price: 2499
    },
    rtpcr: {
      title: 'EXECUTIVE HEALTH CHECK UP',
      description: 'Ideal for people who lead a stressful life,who suffer from diabetes,complain of allergy, etc',
      imagePath: healpingHeart,
      price: 4899
    },
    ppbs: {
      title: 'CARDIAC PROFILE',
      description: 'Ideal for people who lead a stressful life,who suffer from diabetes,complain of allergy, etc',
      imagePath: healpingHeart,
      price: 2499
    },
  };

  let popularPackages = {
    cbc: {
      title: 'Heart Health',
      description: 'Checkup',
      imagePath: heartColorImage,
      price: 499,
      bg: violetBg,
    },
    rtpcr: {
      title: 'Thyroid',
      description: 'Profile',
      imagePath: thyroidColorImage,
      price: 399,
      bg: pinkBg,
    },
    ppbs: {
      title: 'Blood',
      description: 'Checkup',
      imagePath: doctorColorImage,
      price: 699,
      bg: yellowBg,
    }
  };

  let frequentlyBooked = {
    cbc: {
      title: 'MEGSAN LIVE WELL PACKAGE',
      description: 'Ideal for people who lead a stressful life,who suffer from diabetes,complain of allergy, etc',
      imagePath: Rtest,
      price: 699
    },
    rtpcr: {
      title: 'DIABETIC PACKAGE (BASIC)',
      description: 'Ideal for people who lead a stressful life,who suffer from diabetes,complain of allergy, etc',
      imagePath: Rtest,
      price: 1499
    },
    ppbs: {
      title: 'MASTER HEALTH CHECK UP',
      description: 'Ideal for people who lead a stressful life,who suffer from diabetes,complain of allergy, etc',
      imagePath: Rtest,
      price: 2999
    },
  };

  let testimonial = {
    user1: {
      userName: 'Satya Vinay',
      description: 'Went there for Covid RTPCR test,  the results came very fast,  within 5-6 hours of test. The place ambience was good,  clean and it was not crowded during my visit.',
      imagePath: feMaleImage,
    },
    user2: {
      userName: 'Immanuel Dibbagalla',
      description: 'This is 4th time with Megsan but all the times it was great experience.Staff knows all the details and they are very polite. Specially the kompally branch was excellent. Reports recd by midnight without any delays. Best diagnostic centre',
      imagePath: maleImage,
    },
    user3: {
      userName: 'Vadithya Thirupal naik',
      description: 'Good service excellent ambience reports on time n overall am very happy with the organisation...',
      imagePath: feMaleImage,
    }
  };

  let popularCallOfAction = {
    link1: {
      name: 'Featured Checkup',
      href: '/featured-checkups',
    },
    link2: {
      name: 'Women’s Health',
      href: '/womens-health',
    },
    link3: {
      name: 'Men’s Health',
      href: '/mens-health',
    }
  };

  return (
    <Container fluid={true} className={'home'}>
      <HomeBanner />
      <Container fluid={true} className={'need-help-container-fluid'}>
        <NeedHelp/>
      </Container>
      <Container fluid={true} className={'health-type-container-fluid'} >
        <HealthType typesList={ typesList } />
      </Container>
      <Container fluid={true} className={'popular-tests-container-fluid'} >
        <RelatedTests customTitle={'Popular Health Checkup Packages'} type={'popular'} relatedPackages={popularPackages} callOfActions={popularCallOfAction} />
      </Container>
      <Container fluid={true} className={'frequently-booked-container-fluid'} >
        <FrequentlyBooked />
      </Container>
      <Container fluid={true} className={'link-frequently-booked'} >
        <RelatedTests relatedPackages={frequentlyBooked} />
      </Container>
      <Container fluid={true} className={'related-tests-container-fluid'} >
        <RelatedTests customTitle={'Popular Health Checkup Packages'} relatedPackages={relatedPackages} />
      </Container>
      <Container fluid={true} className={'why-book-us-container-fluid'} >
        <HealthType customTitle={'Why book Us'} customDescription={' '} typesList={ whyBookUs } />
      </Container>
      <Container fluid={true} className={'how-we-work-container-fluid'} >
        <HealthType customTitle={'How We Work'} customDescription={' '} typesList={ howWeWork } />
      </Container>
      <Container fluid={true} className={'home-stats-container-fluid'} >
        <Stats />
      </Container>
      <Container fluid={true} className={'testimonial-container-fluid'} >
        <Testimonial testimonial={testimonial} />
      </Container>
    </Container>
  )
}

export default Home
