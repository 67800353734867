import './style.scss'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {isMobile} from "react-device-detect";

const RelatedTests = ({ customTitle, relatedPackages, type, callOfActions }) => {

  const CustomCardRelated = ({ cardTitle, cardImage, cardDescription, cardPrice, type, cardTotalTests }) => {
    return (
      <Card className={'related-tests-container-card ' + type } border="secondary">
        { type === 'basic' ? <Card.Img variant="top" className={'card-img-basic'} src={cardImage}/> : '' }
        <Card.Body>
          { type !== 'basic' ? <Card.Img variant="top" src={cardImage}/> : '' }
          <Card.Title>{cardTitle}</Card.Title>
          <Card.Text>{cardDescription.substring( 0, 100 )}</Card.Text>
          <Card.Text className={'test-count'}>{ cardTotalTests && type === 'basic' ? "Includes "+cardTotalTests+ " tests":""}</Card.Text>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col className={'price'}>
              <span className={'price-value'}><FontAwesomeIcon
                icon={solid('indian-rupee-sign')}/>{cardPrice}</span>
              <span className={'price-text'}>Onwards</span>
            </Col>
            <Col className={'book-now'}><a href={'/contact-us'}>Book Now</a></Col>
          </Row>
        </Card.Footer>
      </Card>
    )
  }

  const CustomCardPopular = ({ cardTitle, cardImage, cardDescription, cardPrice, cardBg }) => {
    return (
      <Card className={'popular-tests-container-card'} border="secondary">
        <div className={'popular-tests-container-card-image'}>
          <Card.Img src={cardBg}/>
        </div>
        <Card.ImgOverlay>
          <div className={'row'}>
            <Col xs={6}>
              <Card.Title>{cardTitle}</Card.Title>
              <Card.Text>{cardDescription.substring( 0, 100 )}</Card.Text>
              <Button variant="danger" size="sm">
                <span className={'price-text'}>Starts from </span>
                <span className={'price-value'}>
                  <FontAwesomeIcon  icon={solid('indian-rupee-sign')}/>{cardPrice}
                </span>
              </Button>
            </Col>
            <Col xs={6}><Card.Img src={cardImage}/></Col>
          </div>
        </Card.ImgOverlay>
      </Card>
    )
  }

  let CustomCard = ( props ) => {
    return type === 'popular'? <CustomCardPopular {...props} /> : <CustomCardRelated {...props} />;
  }

  let list = Object.entries(relatedPackages).map(([key, value]) => {
    return <Col md={ 4 } className={'column'} key={ key }><CustomCard type={type} cardTotalTests={value.totalTests}  cardDescription={value.description} cardImage={value.imagePath} cardTitle={ value.title } cardPrice={ value.price } cardBg={value.bg} /></Col>
  })

  return (
    <div className={ isMobile ? 'related-tests-container' : 'related-tests-container container' }>
      <Row className={'related-tests-container-title'}>
        <Col>{ customTitle && ( <h3>Popular Health Checkup Packages</h3> ) }</Col>
      </Row>
      {
        type === 'popular' && (
          <Row className={'related-tests-container-title'}>
            <Col>{Object.entries(callOfActions).map(([key, value]) => {
              return  <Button variant="outline-secondary" key={ key } onClick={ () => { window.location.href = value.href } }>{value.name}</Button>
            })}</Col>
          </Row>
        )
      }
      <Row className={'related-tests-container-body'}>{list}</Row>
    </div>
  );
}

export default RelatedTests;
