import {
  Row,
  Container, Col,
  Figure,
  Image
} from 'react-bootstrap'
import './style.scss'
import {isMobile} from 'react-device-detect';
import ReactHtmlParser from "react-html-parser";


const ByType = ( { title, imagePath, customClass, content } ) => {
  return (
    <Figure className={ customClass }>
      <div className={'img-bg-div rounded-circle'}>
        <Image
          alt={title}
          src={imagePath}
        />
      </div>
      <Figure.Caption>{title}</Figure.Caption>
      { content ? ReactHtmlParser( '<p>'+content+'</p>' ) : ''}
    </Figure>
  );
}

const HealthType = ( { customTitle, customDescription, columnNumber, typesList }) => {

  let list = Object.entries(typesList).map(([key, value]) => {
    return <Col {...(isMobile && {sm: 2})} className={'column'} key={key}>
      <ByType customClass={value.customClass}
              content={value.content}
              title={value.title}
              imagePath={value.imagePath}
      />
    </Col>
  })

  return (
    <Container className={'health-type-container'}>
      <Row className={'health-type-content'}>
        <h1>{customTitle ? customTitle :'Book Test according to health concern' }</h1>
        <span>{customDescription ? customDescription :'Top Health concern lab tests.'}</span>
        <Row>
          {list}
        </Row>
      </Row>
    </Container>
  )
}

export default HealthType
