import React from "react";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import favIcon from '../../img/megsan-favicon.png'

const Whatsapp = () => {
  return (
    <WhatsAppWidget
      phoneNo="919121377123"
      position="right"
      widgetWidth="300px"
      widgetWidthMobile="260px"
      autoOpen={true}
      autoOpenTimer={5000}
      messageBox={true}
      messageBoxTxt=""
      iconSize="40"
      iconColor="white"
      iconBgColor="#7C42AD"
      headerIcon={favIcon}
      headerIconColor="pink"
      headerTxtColor="white"
      headerBgColor="#7C42AD"
      headerTitle="Megsan"
      headerCaption="Online"
      bodyBgColor="#bbb"
      chatPersonName="Support"
      chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
      footerBgColor="#999"
      btnBgColor="yellow"
      btnTxtColor="black"
      btnTxt="Start Chat"
    />
  );
}

export default Whatsapp;
