import {useState} from 'react'
import {Button, Col, Container, Row, Form} from 'react-bootstrap'

import './style.scss';
import Maps from '../maps'

const ContactForm = () => {
    const [formStatus, setFormStatus] = useState('Send Query')
    const onSubmit = async (e) => {
        e.preventDefault()
        setFormStatus('Submitting...')
        const {name, email, message, service, phonenumber, reservation_date, submit} = e.target.elements
        let conFom = {
            name: name.value,
            email: email.value,
            message: message.value,
            service: service.value,
            phonenumber: phonenumber.value,
            date: reservation_date.value,
            submit: submit.value
        }

        const rawResponse = await fetch("mail.php", {
            method: "POST",
            body: JSON.stringify(conFom)
        })

        const content = await rawResponse.json();
        console.log(content);
        alert(content.message);
        setFormStatus('Send Query');
    }

    const pagePATH = '/home-collections' === window.location.pathname ? 'home' : 'contact';

    const GeneralPacks = [
        "MEGSAN LIVE WELL PACKAGE",
        "DIABETIC PACKAGE (BASIC)",
        "DIABETIC  PACKAGE (EXECUTIVE)",
        "MASTER HEALTH CHECK UP",
        "EXECUTIVE HEALTH CHECK UP",
        "CARDIAC PROFILE",
        "WELL WOMEN  PACKAGE",
        "MEGSAN HEALTHY 2",
        "MEGSAN HEALTHY 1"
    ];

    const HomeOptions = [
        "LAB SERVICES",
        "HOME COLLECTION",
        "COVID-19(RT-PCR)",
    ];

    let packageList = 'contact' === pagePATH ? GeneralPacks : HomeOptions;

    let GeneralPacksList = Object.entries(packageList).map(([key, value]) => {
        return <option key={key} value={value}>{value}</option>
    })

    return (
        <Container className={'contact-us'}>
            <Row>
                <Col md={6}><Maps/></Col>
                <Col md={6}>
                    <h4 className="mb-3">Reach Us</h4>
                    <form onSubmit={onSubmit}>
                        <Row>
                            <Col>
                                <input className="form-control" type="text" id="name" placeholder={'Enter Name'}
                                       required/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <input className="form-control" type="email" id="email" placeholder={'Enter Email'}
                                       required/>
                            </Col>
                            <Col xs={6}>
                                <input className="form-control" type="number" id="phonenumber"
                                       placeholder={'Enter Phone Number'} required/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <Form.Select id={'service'}>
                                    <option value="--select--">--Select services--</option>
                                    {GeneralPacksList}
                                </Form.Select>
                            </Col>
                            <Col xs={6}>
                                <input className="form-control" type="date" id="reservation_date"
                                       placeholder={'Reservation Date'} required/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <textarea className="form-control" rows={10} id='message' placeholder={'Enter Message'}
                                          required/>
                            </Col>
                        </Row>
                        <Button type={'submit'} value={'send'} id={'submit'} variant="secondary">{formStatus}</Button>
                    </form>
                </Col>
            </Row>
        </Container>
    )
}
export default ContactForm
