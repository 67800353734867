import './style.scss'
import { Row, Container, Card, Col, Carousel } from 'react-bootstrap'
import quoteImages from '../../img/quotes.png'
import {isMobile} from "react-device-detect";


const TestimonialCard = ({ imagePath, userName, description }) => {
  return (
    <Card border="secondary">
      <Card.Body>
        <Row className={'image'}>
          <Col className={'image-thumbnail'}><img src={imagePath} alt={'quote'} /></Col>
          <Col className={'image-quote'}><img src={quoteImages} alt={'quote'} /></Col>
        </Row>
        <Card.Text>{description}</Card.Text>
        <Card.Title as={'h5'}>{userName}</Card.Title>
      </Card.Body>
    </Card>
  );
}

const Testimonial = ({ title, description, testimonial }) => {

  const strLength = isMobile ? 100 : 150;

  let list = Object.entries(testimonial).map(([key, value]) => {

    let MCard = () => <TestimonialCard imagePath={value.imagePath} userName={value.userName} description={ value.description.substring(0, strLength) + '..'} />;

    return  !isMobile ? <Col sm={12} md={4} key={ key }><MCard /></Col> : <Carousel.Item  className={'col-md-4 col-sm-12'} key={ key }><MCard /></Carousel.Item>
  })

  let CarouselCard = () => <Carousel>{list}</Carousel>

  return (
    <Container className={'testimonial-container'}>
      <Card>
        <Card.Body>
          <Card.Title>{title
            ? title
            : 'Thousands of Happy Customers'}</Card.Title>
          <Card.Text as={'div'}>
            <Row>
              <Col md={9}>
                {description
                  ? description
                  : 'Our core values are at the heart of all that we do. They are integrated into our daily work lives and help us to remember our customers always comes first, the last thank you should always come from us.'}
              </Col>
            </Row>
            <Row className={'testimonial-container-cards'}>
              { !isMobile ? list : '' }
              { isMobile && <CarouselCard />  }
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default Testimonial
