import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Carousel
} from 'react-bootstrap'
import homeBannerImage from '../../img/Frame.png'
import homeBannerImage2 from '../../img/7616.png'
import {BrowserView, isMobile} from 'react-device-detect';
import { useState } from 'react'
import './style.scss'
import ReactHtmlParser from 'react-html-parser';

const HomeBanner = () => {

  const [searchValue, setSearchValue] = useState('')
  const [searchLocation, setSearchLocation] = useState('')
  const [validated, setValidated] = useState(false)

  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    setSearchLocation('')
    setSearchValue('')
    setValidated(true)
    event.preventDefault()
    event.stopPropagation()
  }

  let lookingFor = [
    'Family Medicine',
    'COVID-19',
    'Mens Health',
    'Blood Test',
    'Lipid Profile',
    'Kidney Function Test',
  ]

  const BannerBody = ( { title, description, link }) => {

    return <Card>
      <Card.Body>
        <Card.Title>{ReactHtmlParser(title)}</Card.Title>
        <Card.Text>{ReactHtmlParser(description)}</Card.Text>
        <div className={'action-link'}>
          <Button variant="primary">Know More</Button>
        </div>
      </Card.Body>
    </Card>
  };

  const ImageComponent = ( { image, altText }) => {

    return <img
        className={ isMobile ? 'd-block w-100' : 'home-banner-content-image' }
        src={image}
        alt={altText}
    />
  }

  const TextBody = ({ title, description, link, image, altText}) => {

    return isMobile ? <Carousel.Caption>
        <Col md={5} className={'home-banner-content-text'}>
          <BannerBody title={title} description={description} />
        </Col>
        <BrowserView className={'col home-banner-content-image'}>
          <ImageComponent image={image} altText={altText} />
        </BrowserView>
      </Carousel.Caption> : <Row>
      <Col md={5} className={'home-banner-content-text'}>
        <BannerBody title={title} description={description} />
      </Col>
      <BrowserView className={'col home-banner-content-image'}>
        <ImageComponent image={homeBannerImage} altText={altText} />
      </BrowserView>
    </Row>
  }

  return (
    <Container className={'home-banner'}>
      <Container className={'home-banner-content'}>
        <Carousel variant="dark">
          <Carousel.Item>
            { isMobile ? <ImageComponent image={homeBannerImage} altText={"First slide"} /> : '' }
            <TextBody
                title={'Book Diagnostic Tests<br/> From Anywhere.'}
                description={'Choose from the list of top-selling packages<br/> tailored for you. We care for your health.'}
                image={homeBannerImage}
                altText={"First slide"}
            />
          </Carousel.Item>
          <Carousel.Item>
            { isMobile ? <ImageComponent image={homeBannerImage2} altText={"Second slide"} /> : '' }
            <TextBody
                title={'Book Diagnostic Tests<br/> From Anywhere.'}
                description={'Choose from the list of top-selling packages<br/> tailored for you. We care for your health.'}
                image={homeBannerImage2}
                altText={"Second slide"}
            />
          </Carousel.Item>
        </Carousel>
      </Container>
      {/*<Container className={'home-search-content'}>*/}
      {/*  <Row>*/}
      {/*    <Col className={'home-search'}>*/}
      {/*      <Card>*/}
      {/*        <Card.Body>*/}
      {/*          <Form noValidate validated={validated} onSubmit={handleSubmit}>*/}
      {/*            <Row className={'home-search-form'}>*/}
      {/*              <Col md={6} className={'home-search-test'}>*/}
      {/*                <InputGroup className="mb-3">*/}
      {/*                  <InputGroup.Text id="basic-addon1">*/}
      {/*                    <FontAwesomeIcon icon={faSearch}/>*/}
      {/*                  </InputGroup.Text>*/}
      {/*                  <Form.Control*/}
      {/*                    id={'search-value'}*/}
      {/*                    type="text"*/}
      {/*                    required*/}
      {/*                    defaultValue={searchValue}*/}
      {/*                    placeholder="Type to search for any test ( ex: blood, health.... ) "*/}
      {/*                  />*/}
      {/*                </InputGroup>*/}
      {/*              </Col>*/}
      {/*              <Col md={4} className={'home-search-location'}>*/}
      {/*                <InputGroup className="mb-3">*/}
      {/*                  <InputGroup.Text id="basic-addon1">*/}
      {/*                    <FontAwesomeIcon icon={faLocation}/>*/}
      {/*                  </InputGroup.Text>*/}
      {/*                  <Form.Control*/}
      {/*                    type="text"*/}
      {/*                    id={'search-location'}*/}
      {/*                    required*/}
      {/*                    defaultValue={searchLocation}*/}
      {/*                    placeholder="Set Your Location"*/}
      {/*                  />*/}
      {/*                </InputGroup>*/}
      {/*              </Col>*/}
      {/*              <Col sm={12} md={1} className={'home-search-submit'}>*/}
      {/*                <Button variant="light" type={'submit'}>*/}
      {/*                  <FontAwesomeIcon icon={faSearch}/>*/}
      {/*                </Button>*/}
      {/*              </Col>*/}
      {/*            </Row>*/}
      {/*          </Form>*/}
      {/*          <Row className={'home-search-text'}>*/}
      {/*            <Col className={'home-search-lookingfor'}>*/}
      {/*              You may be looking for*/}
      {/*            </Col>*/}
      {/*          </Row>*/}
      {/*          <Row className={'home-search-text'}>*/}
      {/*            <Col className={'home-search-lookingfor-buttons'}>*/}
      {/*              {*/}
      {/*                lookingFor.map((name, i) => {*/}
      {/*                  return (<Button key={i} variant="light">{name}</Button>)*/}
      {/*                })*/}
      {/*              }*/}
      {/*            </Col>*/}
      {/*          </Row>*/}
      {/*        </Card.Body>*/}
      {/*      </Card>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</Container>*/}
    </Container>
  )
}

export default HomeBanner
