import { Col, Row } from 'react-bootstrap'
import directorImage from '../../../img/directors-1.png'
import directorImage2 from '../../../img/directors-2.png'
import OurDirectors from '../../our-directors'
import Services from '../../services'
import serviceVision from '../../../img/services/s1.png'
import serviceValue from '../../../img/services/service-value.png'
import serviceMission from '../../../img/services/service-mission.png'

import s1 from '../../../img/services/1.png'
import s2 from '../../../img/services/2.png'
import s3 from '../../../img/services/3.png'
import s4 from '../../../img/services/4.png'
import s5 from '../../../img/services/5.png'
import s6 from '../../../img/services/6.png'

const AboutUs = () => {
  let dList = {
    d1: {
      title: 'Founder & MD',
      imagePath: directorImage,
      content: 'Mr.Jayapal Reddy is a scientist and entrepreneur, leading Megsan since 2014. He brings with him over 15 years of experience in various roles in the pharmaceutical and contract testing industry. Under his leadership, company significantly transformed from a small-sized domestic operation into a global independent product testing company. Jayapal believes in sustainable and innovative business practices. Towards this, he is engaged with strengthening the company capabilities. Jayapal holds a Master\'s degree from Sri Venkateswara University, INDIA.',
      position: 'left',
    },
    d2: {
      title: 'Co-Founder & Director',
      imagePath: directorImage2,
      content: 'Jyothirmai joined the company in 2014 as Co-Founder and Director of Megsan. She brings with her over 14 years of experience in various roles in the pharmaceutical and contract testing industry. Jyothirmai leads core team at Megsan, instrumental in building strong quality systems and heavily involved in setting overall company strategy. Jyothirmai holds a Master\'s degree from Sri Venkateswara University, INDIA. An alumnus of the ISB-Goldman Sachs Women Entrepreneur programme, she was awarded the best prize for “Certificate Programme for Women Entrepreneur” held 2016 at the Indian School of Business, Hyderabad.',
      position: 'right',
    },
  }

  let sList = {
    s1: {
      title: 'Vision',
      imagePath: serviceVision,
      content: 'The Vision to Create Megsan Diagnostics was driven by the Philosophy to provide high quality Standards Accurate Test Results at Affordable Prices to the Masses.',
      position: 'top',
    },
    s2: {
      title: 'Mission',
      imagePath: serviceMission,
      content: 'The Vision to Create Megsan Diagnostics was driven by the Philosophy to provide high quality Standards Accurate Test Results at Affordable Prices to the Masses.',
      position: 'top',
    },
    s3: {
      title: 'Value',
      imagePath: serviceValue,
      content: 'These values, which guide Megsan Diagnostic\'s mission to this day, are an expression of the vision and intent of our founders.',
      position: 'top',
    },
  }

  let serviceList = Object.entries(sList).map(([key, value]) => {
    return <Col md={4} key={key}><Services position={value.position}
                                           title={value.title}
                                           imagePath={value.imagePath}
                                           content={value.content}/></Col>
  })


  let vList = {
    s1: {
      title: 'New age Diagnostics ',
      imagePath: s1,
      content: 'At Konacc, we incorporate contemporary best practices in radiology & pathology and augment this effort with exemplary hospitality, quality and consistency in our services.',
      position: 'bottom',
    },
    s2: {
      title: 'Radiology & Pathology',
      imagePath: s2,
      content: 'We provide both Radiology and pathology services which is convenient for the customer at a one stop for all diagnostics needs.',
      position: 'bottom',
    },
    s3: {
      title: 'New Age reporting',
      imagePath: s3,
      content: 'A click of a button and our customers get detailed reporting analysis of there past and present to formulate a detailed health graphical comparison report for all there test.',
      position: 'bottom',
    },
    s4: {
      title: 'Technology advancement',
      imagePath: s4,
      content: 'State of the art cloud services are incorporated at Konacc to make digital reports availability at your fingertips for a lifetime.',
      position: 'bottom',
    },
    s5: {
      title: 'Home sample collection',
      imagePath: s5,
      content: 'On receiving a request through our Website, App or call center a Konnect trained technicians arrives to collect samples. Reports can be send to your phone via sms/email or can be collected at any of our centers.',
      position: 'bottom',
    },
    s6: {
      title: 'R&D Department',
      imagePath: s6,
      content: 'We keep adapting. With this intention, we have an in-house R&D department who innovate products that improve the diagnostics precision for the common healthcare dilemmas.',
      position: 'bottom',
    },
  }

  let visionList  = Object.entries(vList).map(([key, value]) => {
    return <Col sm={12} md={4} key={key}><Services position={value.position}
                                           title={value.title}
                                           imagePath={value.imagePath}
                                           content={value.content}/></Col>
  })



  return (
    <div className={'about-us-content'}>
      <div className={'directors'}>
        <h2>Its Good to know<br/>
          why we never rest</h2>
        <p>One of the specialty diagnostic centre and pathology laboratory in
          India an end-to-end healthcare service provider.</p>
        <p>Offering a wide spectrum of diagnostic tests, corporate check-ups,
          health care packages and more with
          uncompromising quality standards under one roof, along with the
          provision for blood sample collection at home,
          quality diagnostic service through its fully automated diagnostic
          equipment having capacity of performing
          more than 1500 tests/hour.</p>
        <div className={'directors-view'}><OurDirectors directorsList={dList}/></div>
        <div className={'directors-vision'}>
          <Row> {serviceList} </Row>
        </div>
      </div>
      <div className={'vision'}>
        <Row className={'vision-title'}>
          <Col md={8}>
            <h2>What makes Megsan diagnostics
              different from others</h2>
          </Col>
        </Row>
        <Row className={'vision-description'}>
          <Col md={12}>
            Providing quality diagnostics services is our passion, improving the
            quality of lives is our reward. Here are
            a few pointers that separate us from the crowd.
          </Col>
        </Row>
        <div className={'vision-list'}>
          <Row> {visionList} </Row>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
