import { Row } from 'react-bootstrap'
import s7 from '../../../img/services/7.png'
import s8 from '../../../img/services/8.png'
import s9 from '../../../img/services/9.png'
import s10 from '../../../img/services/10.png'
import s11 from '../../../img/services/11.png'
import s12 from '../../../img/services/12.png'
import ServicesBlock from '../../services/Block'
import RelatedTests from '../../related-tests'
import healpingHeart from '../../../img/helping-heart.png'

import diabetesPopularPackages from '../../../img/packages/diabetes.png'
import Testimonial from '../../testimonial'
import feMaleImage from '../../../img/female.png'
import maleImage from '../../../img/male.png'

const OurServices = () => {

  let sList = {
    s1: {
      title: 'MRI Scan',
      imagePath: s7,
      content: 'We provide magnetic resonance imaging or MRI, a diagnostic imaging technique that obtains minute images of your body\'s internal organs and tissues.',
      position: 'top',
    },
    s2: {
      title: 'CT Scan',
      imagePath: s8,
      content: 'We provide multiple detectors computed tomography that helps pinpoint the location of abnormalities.',
      position: 'top',
    },
    s3: {
      title: 'Ultrasound',
      imagePath: s9,
      content: 'We provide Ultrasound techniques to provide accurate pictures of the body’s internal structures without using ionizing radiation.',
      position: 'top',
    },
    s4: {
      title: 'X-Ray',
      imagePath: s10,
      content: 'We provide X-ray or radiography to get greater visibility of specific organs such as blood vessels and tissues.',
      position: 'top',
    },
    s5: {
      title: 'Mammography',
      imagePath: s11,
      content: 'We provide advanced Mammography procedures to see the inside tissues of the breasts.',
      position: 'top',
    },
    s6: {
      title: 'Lab',
      imagePath: s12,
      content: 'We provide Treadmill Test (TMT) to find the stress on the heart. PFT - Pulmonary function tests (PFTs) to find out whether the lungs are working well.',
      position: 'top',
    },
  }

  const pathologyBlock = {
    heading: 'Pathology',
    subHeading: 'Technology Highlights:',
    description: 'To monitor your health status better, pathology testing helps not just to diagnose or test a disease but to \n' +
      'prevent it as well, by detecting possible precursors. These services include tests done on clinical specimens \n' +
      'for the purpose of obtaining relevant information of your health to define appropriate health and lifestyle \n' +
      'precautions. Some procedures are invasive while others are not. Our main aim is to provide quality services \n' +
      'focused on prevention and early detection of diseases, using latest technology, sophisticated equipment and \n' +
      'follow stringent quality measures. With our complete spectrum of services from routine tests such as CBP or \n' +
      'Blood Glucose to highly specialized investigations for infectious diseases, cancer markers, hormonal assays, \n' +
      'therapeutic drug assays etc. Each individual discipline is headed by experienced, professionally qualified \n' +
      'medical doctors supported by a team of qualified and trained technologists. All quantitative investigations \n' +
      'are undertaken on fully automated state of art the analyzers, to eliminate the chance of any human error.',
    list: {
      s1: {
        title: 'LCMS',
        imagePath: s10,
        content: 'We utilise LCMS technique for drug and food analysis.',
        position: 'top',
      },
      s2: {
        title: 'FLOW CYTOMETRY',
        imagePath: s10,
        content: 'Identification of type of cells in blood and bone marrow through Flow CYTOMETRY',
        position: 'top',
      },
      s3: {
        title: 'IMMUNOPHENO TYPING',
        imagePath: s10,
        content: 'Specially used technique for identification of Leukamia/Lymphoma',
        position: 'top',
      }
    },
  }
  
  const radiologyBlock =  {
    heading: 'Radiology',
    subHeading: '',
    description: 'Radiology provides critical inputs when doctors and physicians are in the process of determining and \n' +
      'identifying a patients medical problem. Reliable radiology services are essential for medical professionals\n' +
      'to confirm or negate certain medical conditions. At Tenet, every procedure is supervised by our team of \n' +
      'highly qualified and experienced Radiologists, to ensure that the results are accurate and clinically relevant \n' +
      'to enable better diagnosis. With our commitment to reliable, accurate and responsive diagnosis, Konnect\n' +
      'houses a complete spectrum of radiology services like the High Resolution 3D Ultrasound and 64 slice \n' +
      'Cardiac CT scanner.',
    list: sList,
  }

  let relatedPackages = {
    cbc: {
      title: 'MEGSAN LIVE WELL PACKAGE',
      description: 'Ideal for people who suspect having lifestyle disorders such as anemia, anxiety, etc',
      imagePath: healpingHeart,
      price: 699
    },
    rtpcr: {
      title: 'DIABETIC PACKAGE (BASIC)',
      description: 'Ideal for people who lead a stressful life, who suffer from diabetes, complain of allergy, etc',
      imagePath: healpingHeart,
      price: 1499
    },
    ppbs: {
      title: 'DIABETIC PACKAGE (EXECUTIVE)',
      description: 'Ideal for people who lead a fast life and often complain of fatigue, tiredness and joint pain',
      imagePath: healpingHeart,
      price: 2499
    },
  };

  let popularPackages = {
    cbc: {
      title: 'MASTER HEALTH CHECK UP',
      description: 'Ideal for people who are getting their health check up done for the first time with the advantage of advanced Lipid Testing',
      imagePath: diabetesPopularPackages,
      price: 2999,
      totalTests: 30
    },
    rtpcr: {
      title: 'EXECUTIVE HEALTH CHECK UP',
      description: 'Ideal for people who are getting their health check up done for the first time',
      imagePath: diabetesPopularPackages,
      price: 4899,
      totalTests: 35
    },
    ppbs: {
      title: 'CARDIAC PROFILE',
      description: 'Ideal for men above the age of 60 years, who want to get a comprehensive health check up done',
      imagePath: diabetesPopularPackages,
      price: 299,
      totalTests: 5
    },
    cbc2: {
      title: 'WELL WOMEN PACKAGE',
      description: 'Ideal for female above the age of 60 years, who want to get a comprehensive health check up done',
      imagePath: diabetesPopularPackages,
      price: 6599,
      totalTests: 35
    },
    rtpcr2: {
      title: 'MEGSAN HEALTHY 1',
      description: 'Ideal for women between the ages 41-50 years,who want to get a comprehensive health check up done',
      imagePath: diabetesPopularPackages,
      price: 2890,
      totalTests: 43
    },
    ppbs2: {
      title: 'MEGSAN HEALTHY 2',
      description: 'Ideal for women between the ages 41-50 years,who want to get a comprehensive health check up done',
      imagePath: diabetesPopularPackages,
      price: 4370,
      totalTests: 46
    },
  };

  let testimonial = {
    user1: {
      userName: 'Satya Vinay',
      description: 'Went there for Covid RTPCR test,  the results came very fast,  within 5-6 hours of test. The place ambience was good,  clean and it was not crowded during my visit.',
      imagePath: feMaleImage,
    },
    user2: {
      userName: 'Immanuel Dibbagalla',
      description: 'This is 4th time with Megsan but all the times it was great experience.Staff knows all the details and they are very polite. Specially the kompally branch was excellent. Reports recd by midnight without any delays. Best diagnostic centre',
      imagePath: maleImage,
    },
    user3: {
      userName: 'Vadithya Thirupal naik',
      description: 'Good service excellent ambience reports on time n overall am very happy with the organisation...',
      imagePath: feMaleImage,
    }
  };

  return (
    <div className={'our-services-content'}>
      <ServicesBlock heading={''} description={''} list={sList} subHeading={''} />
      <ServicesBlock {...pathologyBlock} />
      <ServicesBlock {...radiologyBlock} />
      <Row className={'related-tests-container-fluid'} >
        <RelatedTests customTitle={'Popular Health Checkup Packages'} relatedPackages={relatedPackages} />
      </Row>
      <Row className={'related-tests-container-fluid'} >
        <RelatedTests customTitle={'Our Popular Health Packages'} type={'basic'} relatedPackages={popularPackages} />
      </Row>
      <Row className={'testimonial-container-fluid'} >
        <Testimonial testimonial={testimonial} />
      </Row>
    </div>
  )
}

export default OurServices
