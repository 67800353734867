import React from 'react'

const Maps = () => {

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <iframe
          title={'google map'}
        src={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15226.267004198693!2d78.43514432868638!3d17.432567408823417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91ae6bf94d55%3A0x660af16c093e06bb!2sMEGSAN%20DIAGNOSTICS%20CORPORATE%20OFFICE!5e0!3m2!1sen!2sin!4v1668580869379!5m2!1sen!2sin'}
        width={'100%'}
        height={'100%'}
      ></iframe>
    </div>
  )
}

export default Maps
