import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss';
import ReactHtmlParser from 'react-html-parser';


const Info = ({ fontAwesomeIconName, title, content }) => {
  return (
    <div className={'info-content'}>
      <Row>
        <Col xs={2}> </Col>
        <Col xs={8}>{
          title ? (
            <Row className={'info-content-title'}>
              <span>{title}</span>
            </Row>
          ) : ''
        }</Col>
        <Col xs={2}> </Col>
      </Row>
      <Row>
        {
          fontAwesomeIconName ? (
            <Col xs={2} className={'info-content-icon'}>
              <FontAwesomeIcon icon={fontAwesomeIconName}/>
            </Col>
          ) : ''
        }
        {
          content ? (
            <Col className={'info-content-body'}>{ ReactHtmlParser(content) }</Col>
          ) : ''
        }
      </Row>
    </div>
  )
}

export default Info
